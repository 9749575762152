import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SITE_NAVIGATION_CREATE_MODAL_ID } from 'global-ids';
import INavigation, { INavigationParent, NAVIGATION_PARENT_TYPE, NavigationParentType, navigationParentTypeToPageType } from 'models/INavigation';
import { getDefaultNavigation, setEditableFolderIdInSiteNavigation, updateNavigation } from 'services/navigationv2';
import usePageCreator from 'hooks/use-page-creator';
import { useAdminTranslation } from 'hooks/use-translation';
import { Body, Column, Container, Description, Header, IconContainer, ItemContainer, Name, Title } from './styles';
import { generateRandomHexID } from 'utils';
import { getFolderName } from 'services/navigationv2/utils';
import { slugify } from 'shared/string-utils';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';
import { usePageNavigationContext } from 'components/admin-bridge/PageNavigation/Context';
import { SiteNavigationItemTypeIcon } from '../SiteNavigationItem/styles';

type CreationList = {
  description: TI18nKey;
  icon: string;
  id: string;
  name: TI18nKey;
  type: NavigationParentType;
}[];

const CREATION_LIST: CreationList = [
  {
    name: 'ADMIN_LABEL_PAGE',
    description: 'CREATE_POWERFUL_OFFLINE_EXPERIENCES',
    icon: 'pageOutline',
    id: 'landing',
    type: NAVIGATION_PARENT_TYPE.landing,
  },
  {
    name: 'ADMIN_LABEL_CHANNEL',
    description: 'GO_LIVE_WITH_AN_INTERACTIVE_VIDEO_EXPERIENCE',
    icon: 'channelSelectV3Outline',
    id: 'channel',
    type: NAVIGATION_PARENT_TYPE.channel,
  },
  {
    name: 'FOLDER',
    description: 'ORGANIZE_YOUR_PAGES_AND_LIVE_CHANNELS',
    icon: 'folderOutline',
    id: 'folder',
    type: NAVIGATION_PARENT_TYPE.folder,
  },
];

interface ICreateModalProps {
  toggleMenu: () => void;
}

const CreateModal = React.forwardRef(({
  toggleMenu,
}: ICreateModalProps, ref) => {
  const dispatch = useDispatch();
  const { t } = useAdminTranslation();
  const [createNewPage] = usePageCreator();
  const navigation = useSelector(getDefaultNavigation);
  const { closeNavigation } = usePageNavigationContext();

  const createNewChannelOrLanding = useCallback((type: NavigationParentType) => {
    createNewPage?.(navigationParentTypeToPageType[type]);
    closeNavigation();
  }, [closeNavigation, createNewPage]);

  const createNewFolder = useCallback(() => {
    const newFolderRandomId = generateRandomHexID();
    const newFolderName = getFolderName(navigation, 'New Folder');

    dispatch(updateNavigation({
      ...navigation,
      parents: [
        {
          id: newFolderRandomId,
          name: newFolderName,
          slug: slugify(newFolderName),
          type: NAVIGATION_PARENT_TYPE.folder,
          children: [],
        },
        ...navigation?.parents as INavigationParent[],
      ],
    } as INavigation));
    dispatch(setEditableFolderIdInSiteNavigation(newFolderRandomId));
    toggleMenu();
  }, [dispatch, navigation, toggleMenu]);

  const create = (type: NavigationParentType) => {
    switch (type) {
      case NAVIGATION_PARENT_TYPE.channel:
        return createNewChannelOrLanding(navigationParentTypeToPageType[type]);
      case NAVIGATION_PARENT_TYPE.landing:
        return createNewChannelOrLanding(navigationParentTypeToPageType[type]);
      case NAVIGATION_PARENT_TYPE.folder:
        return createNewFolder();
    }
  };

  const getIsDisabled = useCallback((type: NavigationParentType) => {
    return !createNewPage && (type === NAVIGATION_PARENT_TYPE.landing || type === NAVIGATION_PARENT_TYPE.channel);
  }, [createNewPage]);

  return (
    <Container ref={ref as any} id={SITE_NAVIGATION_CREATE_MODAL_ID} data-testid={SITE_NAVIGATION_CREATE_MODAL_ID}>
      <Header>
        <Title>{t('ADMIN_LABEL_CREATE')}</Title>
      </Header>
      <Body>
        {CREATION_LIST.map((item) => {
          const handleCick = () => create(item.type);
          return (
            <ItemContainer
              key={item.id}
              onClick={handleCick}
              data-testid={`site-navigation-create-${item.id}`}
              disabled={getIsDisabled(item.type)}
            >
              <IconContainer>
                <SiteNavigationItemTypeIcon iconName={item.icon} />
              </IconContainer>
              <Column>
                <Name>{t(item.name as any)}</Name>
                <Description>{t(item.description as any)}</Description>
              </Column>
            </ItemContainer>
          );
        })}
      </Body>
    </Container>
  );
});

export default CreateModal;
