import React, { PropsWithChildren } from 'react';
import { PageDraggableBlockWrapper } from './styles';
import useDragDrop from 'hooks/use-drag-drop';

interface IPageDraggableBlock extends PropsWithChildren {
  id: string;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
}

const PageDraggableBlock =  ({ id, children, index, moveItem }: IPageDraggableBlock) => {
  const { handlerId, isDragging, ref } = useDragDrop({
    id,
    index,
    type: 'PAGE_EDITOR_BLOCKS',
    canReorder: (item, monitor, dragIndex, hoverIndex) => {
      // Determine rectangle on screen
      const hoverBoundingRect = item.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset?.y || 0) - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging leftwards and rightwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return false;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return false;
      }
      return true;
    },
    onReorder: moveItem,
  });

  return (
    <PageDraggableBlockWrapper ref={ref} data-handler-id={handlerId} isDragging={isDragging}>
      {children}
    </PageDraggableBlockWrapper>
  );
};

export default PageDraggableBlock;
