import React from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import Thumbnail from 'components/ui/Thumbnail';
import useElementSize from 'hooks/use-element-size';
import type { IVideo } from 'models';
import { TEXT_100, TEXT_200, TEXT_300, TITLE_FONT_FAMILY } from 'style/constants';
import { TEXT_LABEL3, TEXT_P2, MULTI_LINE_ELLIPSIS, TEXT_H5, THEME_PADDING } from 'style/mixins';
import VideoLink from 'components/ui/VideoLink';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';

interface VideoItemProps {
  doc: IVideo;
  isEditing: boolean;
  isGridMobile: boolean;
  onClick?(): void;
  onEdit?(): void;
  onRemove?(id?: string): void;
}

export default function VideoItem({
  doc,
  isEditing,
  onClick,
  onEdit,
  onRemove,
}: VideoItemProps) {
  const [containerRef, { width: containerWidth }] = useElementSize();

  const handleRemove = () => {
    onRemove?.(doc._id);
  };

  return (
    <VideoItemContainer ref={containerRef}>
      <StyledQuickEditOverlay
        testId="offlineVideoItemQuickEditOverlay"
        titleKey="ADMIN_VIDEO_OFFLINE_VIDEO"
        onEdit={onEdit}
        onRemove={onRemove ? handleRemove : undefined}
        width={containerWidth}
      >
        <VideoLink isDisabled={isEditing} onClick={onClick} tag={doc._id}>
          <StyledThumbnail src={doc.thumbnail} />
        </VideoLink>
        <VideoInfo>
          {doc.title ? (
            <VideoLink isDisabled={isEditing} onClick={onClick} tag={doc._id}>
              <Title data-testid="gridVideoItemTitle">{doc.title}</Title>
            </VideoLink>
          ) : null}
          {doc.description ? (
            <Description maxLines={containerWidth <= 900 ? 4 : 5}>
              {doc.description}
            </Description>
          ) : null}
          {doc.created ? (
            <PostedDate>
              {moment(new Date(doc.created)).fromNow()}
            </PostedDate>
          ) : null}
        </VideoInfo>
      </StyledQuickEditOverlay>
    </VideoItemContainer>
  );
}

const VideoInfo = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const StyledThumbnail = styled(Thumbnail).attrs({ renderPlayIcon: true })``;

const Title = styled.span.attrs({
  maxLines: 2,
})`
  ${TEXT_LABEL3}
  font-family: ${TITLE_FONT_FAMILY};
  font-weight: 600;
  color: ${TEXT_100};
  ${MULTI_LINE_ELLIPSIS}
`;

const Description = styled.span`
  ${TEXT_P2}
  font-weight: 400;
  color: ${TEXT_200};
  ${MULTI_LINE_ELLIPSIS};
`;

const PostedDate = styled.span`
  ${TEXT_H5}
  font-weight: 400;
  color: ${TEXT_300};
`;

const query = (
  maxWidth: number,
  containerWidth: number,
  thumbnailWidth: number | string,
  titleSize: number,
  descriptionSize: number,
  postedDateSize: number,
  videoInfoPadding: number,
  extraCss?: string | string[],
) => containerWidth <= maxWidth ? css`
  & ${StyledThumbnail} {
    width: ${typeof thumbnailWidth === 'number' ? `${thumbnailWidth}px` : thumbnailWidth};
  }
  & ${Title} {
    font-size: ${titleSize}px;
  }
  & ${Description} {
    font-size: ${descriptionSize}px;
  }
  & ${PostedDate} {
    font-size: ${postedDateSize}px;
  }
  & ${VideoInfo} {
    padding: 0 ${videoInfoPadding}px;
  }
  ${extraCss}
` : '';

const VideoItemContainer = styled.div``;

const StyledQuickEditOverlay = styled(QuickEditOverlay)<{ width: number; }>`
  display: flex;
  gap: 20px;
  margin: 0;
  ${THEME_PADDING}

  ${({ width }) => css`
    ${query(2560, 0, 800, 24, 18, 20, 110)}
    ${query(1919, width, 640, 24, 18, 22, 60)}
    ${query(1439, width, 610, 24, 18, 22, 60)}
    ${query(1279, width, 482, 18, 14, 14, 40)}
    ${query(1023, width, 420, 16, 14, 14, 40)}
    ${query(900, width, '100%', 16, 13, 14, 0, 'flex-direction: column;')}
  `}
`;
