import { moveItem } from 'hooks/use-drag-drop';
import React, { PropsWithChildren } from 'react';
import { ILandingPageContent, ILandingPageContentBlockKinds, IPagesRowBlock, ITaggedVideosBlock } from 'services/app/models/ILandingPageContent';
import { getCountdownTitle, getCountdownStartTime, getObjectId } from 'services/app/selectors';
import { isMobileLayout } from 'services/device';
import { isMobilePanelEmbed } from 'services/user-layout';
import { AddButton, GridContainer, HoverBoundary, ModalWrapper, Wrapper } from './styles';
import Countdown from 'components/ui/Countdown';
import { useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PageDraggableBlock from './DraggableBlock';
import LandingContentItem from 'components/LandingContentItem';

interface AdminGridViewProps extends PropsWithChildren {
  activeModalIndex: number;
  landingContent: ILandingPageContent[];
  onAdd: (index: number) => void;
  onGridContentEdit: (kind: ILandingPageContentBlockKinds, index: number) => void;
  onPageBlockEdit: (data: any) => void;
  onPagesRowEdit: (index: number, data: IPagesRowBlock) => void;
  onRemove: (index: number) => void;
  onReorder: (landingContent: ILandingPageContent[]) => void;
  onTaggedVideoEdit: (block: ITaggedVideosBlock) => void;
}

const AddHover = ({ onAdd, newPageBlockIndex, children: nestedChildren }) => {
  const handleAdd = () => {
    onAdd(newPageBlockIndex);
  };

  return (
    <HoverBoundary>
      <ModalWrapper>
        {nestedChildren}
        <AddButton onClick={handleAdd} />
      </ModalWrapper>
    </HoverBoundary>
  );
};

const AdminGridView: React.FC<AdminGridViewProps> = ({
  activeModalIndex,
  landingContent,
  onAdd,
  onReorder,
  children,
  onPagesRowEdit,
  onGridContentEdit,
  onPageBlockEdit,
  onRemove,
  onTaggedVideoEdit,
}) => {
  const isMobile = useSelector(isMobileLayout);
  const countdownMessage = useSelector(getCountdownTitle);
  const countdownStartTime = useSelector(getCountdownStartTime);
  const onMovePageBlock = (dragIndex: number, hoverIndex: number) => {
    onReorder(moveItem(landingContent, dragIndex, hoverIndex));
  };

  return (
    <GridContainer>
      {isMobile && (
        <Countdown message={countdownMessage} startTime={countdownStartTime} />
      )}
      {landingContent?.length ? null : children}
      <DndProvider backend={HTML5Backend}>
        <Wrapper>
          {landingContent.map((item, index) => (
            <PageDraggableBlock
              key={index}
              id={item.arrayId}
              index={index}
              moveItem={onMovePageBlock}
            >
              <>
                <AddHover newPageBlockIndex={index} onAdd={onAdd}>
                  {activeModalIndex === index ? children : null}
                </AddHover>
                <LandingContentItem
                  index={index}
                  item={item}
                  onGridContentEdit={onGridContentEdit}
                  onPageBlockEdit={onPageBlockEdit}
                  onPagesRowEdit={onPagesRowEdit}
                  onRemove={onRemove}
                  onTaggedVideoEdit={onTaggedVideoEdit}
                />
                {index === landingContent.length - 1 && (
                  <AddHover newPageBlockIndex={index + 1} onAdd={onAdd}>
                    {activeModalIndex === index + 1 ? children : null}
                  </AddHover>
                )}
              </>
            </PageDraggableBlock>
          ))}
        </Wrapper>
      </DndProvider>
    </GridContainer>
  );
};

export default AdminGridView;
