import React from 'react';
import PropTypes from 'prop-types';
import { computeEntries } from 'array-utils';
import ScrollSlider from 'components/ui/ScrollSlider';
import TranslatedText from 'components/i18n/TranslatedText';
import { withEndUserT, DynamicTranslationType } from 'hooks/use-translation';
import { youtubeVideoFallbackThumbnail } from 'services/video/utils';
import PlaylistItem from '../PlaylistItem';
import {
  ClickPreventer,
  getSizes,
  StyledQuickEditOverlay,
  DesktopGridButton,
  MobileGridButton,
  EmptyButton,
  PlaylistHeader,
  PlaylistTitle,
  PlaylistCount,
  PlaylistNumber,
} from '../styles';

export default withEndUserT(
  class PlaylistRow extends React.Component {
    static propTypes = {
      arrayId: PropTypes.string,
      endUserT: PropTypes.func.isRequired,
      hideArrows: PropTypes.bool.isRequired,
      isEditing: PropTypes.bool.isRequired,
      isGridMobile: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      onEdit: PropTypes.func,
      onRemove: PropTypes.func,
      playlistId: PropTypes.string,
      showBorder: PropTypes.bool.isRequired,
      slideContainerMaxWidth: PropTypes.number.isRequired,
      slidesWidth: PropTypes.number.isRequired,
      totalSlidesToShow: PropTypes.number.isRequired,
      videos: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    };

    static defaultProps = {
      arrayId: null,
      onClick: undefined,
      onEdit: () => {},
      onRemove: undefined,
      playlistId: null,
    };

    renderPlaylistHeader({ name, count }) {
      const { arrayId, endUserT, isGridMobile, isEditing, playlistId } = this.props;
      if (isGridMobile || !(count || name)) {
        return null;
      }
      let title = name;
      const localizedName = endUserT([
        DynamicTranslationType.playlistTitle,
        { playlistId: playlistId || arrayId },
      ]);
      const localizedCountAndVideoLabel = endUserT(
        [DynamicTranslationType.playlistCountAndVideo, { variables: { count } }],
        ['GRID_PLAYLIST_COUNT_VIDEOS', { count }],
      );

      let VideoCountAndLabelComponent = (
        <>
          <PlaylistNumber>{count}</PlaylistNumber>
          <TranslatedText stringKey="GRID_PLAYLIST_VIDEOS" />
        </>
      );
      if (!isEditing) {
        title = localizedName || name;

        if (localizedCountAndVideoLabel) {
          const Component = 'span';
          VideoCountAndLabelComponent = (
            <Component style={{ marginRight: '0.25em' }}>
              {localizedCountAndVideoLabel}
            </Component>
          );
        }
      }

      return (
        <PlaylistHeader>
          <PlaylistTitle data-testid="playlistTitle">{title}</PlaylistTitle>
          <PlaylistCount>
            {VideoCountAndLabelComponent}
          </PlaylistCount>
        </PlaylistHeader>
      );
    }

    render() {
      const {
        isGridMobile,
        showBorder,
        slidesWidth,
        name,
        onClick,
        videos,
        totalSlidesToShow,
        onRemove,
        onEdit,
        isEditing,
        playlistId,
        slideContainerMaxWidth,
        hideArrows,
      } = this.props;
      const filteredVideos = videos ? videos.filter(video => video._id) : [];
      const count = filteredVideos.length;
      const gridSizes = getSizes(isGridMobile);
      const slidesToShow = Math.min(totalSlidesToShow, count);
      const flushLeft = slidesToShow === count;
      const GridButton = isGridMobile ? MobileGridButton : DesktopGridButton;
      const ButtonContainer = flushLeft ? EmptyButton : GridButton;
      const playlistData = { count, name };
      return (
        <StyledQuickEditOverlay
          isGridMobile={isGridMobile}
          onEdit={onEdit}
          onRemove={onRemove}
          showBorder={showBorder}
          showEditingBorder={isEditing}
          testId="playlistRowQuickEdit"
          titleKey="ADMIN_LABEL_PLAYLIST"
          width={isGridMobile ? (slideContainerMaxWidth - 72) : slidesWidth}
        >
          <ClickPreventer preventClicks={isEditing}>
            <ScrollSlider
              arrowLeftIconName="arrowLeft"
              arrowRightIconName="arrowRight"
              flushLeft={flushLeft}
              fullwidth
              hideArrows={hideArrows}
              isGrid
              isGridMobile={isGridMobile}
              isTouchSlider
              items={computeEntries(filteredVideos, '_id', false).map(({ key, value }) => ({ ...value, key }))}
              mobileTitle={name}
              playlistData={playlistData}
              renderHeader={() => this.renderPlaylistHeader(playlistData)}
              slideAmount={slidesToShow - 1}
              slideButton={ButtonContainer}
              slideMargin={gridSizes.THUMBNAIL_MARGIN}
              slidesHeight={gridSizes.PLAYLIST_ROW_MIN_HEIGHT}
              slidesShown={slidesToShow}
              slideWidth={gridSizes.THUMBNAIL_WIDTH_SMALL}
            >
              {
              (doc, index) => {
                const withFallbackThumbnail = youtubeVideoFallbackThumbnail(doc);
                const { created, durationSeconds, thumbnail, _id, title } = withFallbackThumbnail;
                return (
                  <PlaylistItem
                    created={created}
                    doc={withFallbackThumbnail}
                    duration={durationSeconds}
                    image={thumbnail}
                    index={index}
                    isEditing={isEditing}
                    isGridMobile={isGridMobile}
                    onClick={() => onClick({ _id, title }, index)}
                    playlistId={playlistId}
                    tag={_id}
                    title={title}
                  />
                );
              }
            }
            </ScrollSlider>
          </ClickPreventer>
        </StyledQuickEditOverlay>
      );
    }
  },
);
